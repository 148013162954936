<script lang="ts" setup>
const props = defineProps<{
	imagePopup?: string;
	spinsRemain?: number;
	betLevel?: number;
	gameId?: number;
}>();

type SocketFreeSpinData = {
	gameId?: number;
	spinsRemain?: number;
	spinsTotal?: number;
};

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const { t } = useT();
const { open } = useAppModals();
const { select } = useGamesState();
const { handleOpenGame } = useOpenGame(open);
const freeSpinSocketData: Ref<SocketFreeSpinData | undefined> = useState("freeSpinSocketData");
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();

const spinsRemain = ref(props.spinsRemain);
const game = computed(() => {
	const gameInfo = select([props.gameId || 0]);
	return gameInfo[0];
});
const gameImg = computed(() => `${baseImageUrl}${game.value?.logo || game.value?.img}`);

const handleClaimClick = async () => {
	if (!isSweepStakes.value) {
		await handleSwitchGameMode("SweepStakes");
	}

	await nextTick();
	handleOpenGame(game.value?.slug);
};

if (process.client) {
	watch(
		() => freeSpinSocketData.value,
		() => {
			if (props.gameId === freeSpinSocketData.value?.gameId) {
				spinsRemain.value = props.spinsRemain;
				spinsRemain.value = freeSpinSocketData.value?.spinsRemain;
			}
		}
	);
}
</script>

<template>
	<ACard
		class="vip-free-spin"
		width="584px"
		height="760px"
		:imgAttrs="{
			width: 584,
			height: 761,
			src: imagePopup,
			alt: 'vip-club-bg',
			format: 'avif',
			loading: 'lazy'
		}"
		alt="vip-club-bg"
		@click="handleClaimClick"
	>
		<template #topLine>
			<AText :modifiers="['uppercase', 'medium']" as="div">
				<i18n-t keypath="freeSpin.promo.card.text.top.text">
					<template #key>
						<span class="text-primary-400">{{ t("freeSpin.promo.card.text.top.key") }}</span>
					</template>
				</i18n-t>
			</AText>
		</template>
		<template #top>
			<MPromotionHeadlineFont>{{ game.title }}</MPromotionHeadlineFont>
			<AText :size="28" :modifiers="['uppercase', 'bold', 'nowrap']" as="div">
				<i18n-t keypath="freeSpin.promo.card.text.spin">
					<template #key>
						<span class="text-primary-400">{{ spinsRemain }}</span>
					</template>
				</i18n-t>
			</AText>
			<AText :size="16" :modifiers="['bold', 'nowrap']" as="div">
				<i18n-t keypath="freeSpin.promo.card.text.level">
					<template #key>
						{{ betLevel }}
					</template>
				</i18n-t>
			</AText>
		</template>
		<template #bottom>
			<div class="bottom-image">
				<NuxtImg
					class="image-stars"
					src="/nuxt-img/promotion/free-spin-leaves-stars.png"
					format="webp"
					width="438"
					height="294"
					loading="lazy"
					alt="free-spin-stars"
				/>
				<NuxtImg
					class="image-leave"
					src="/nuxt-img/promotion/free-spin-leave.png"
					format="avif"
					width="249"
					height="261"
					loading="lazy"
					alt="free-spin-leave"
				/>
				<div class="image-game">
					<NuxtImg :src="gameImg" format="avif" width="500" height="560" loading="lazy" alt="free-spin-game" />
				</div>
			</div>
			<AButton variant="primary" size="xl" :modifiers="['wide']" @click="handleClaimClick">
				<AText :size="18" :modifiers="['medium']"> {{ t("freeSpin.promo.card.button") }}</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.vip-free-spin {
	--a-card-default-top-gap: 8px;

	width: 292px;
	height: 380px;
}

.bottom-image {
	position: absolute;
	bottom: 16px;
	left: 0;
	right: 0;
	z-index: 0;
	.image-stars {
		width: 292px;
		height: 196px;
	}
	.image-leave {
		position: absolute;
		width: 165px;
		height: 173px;
		bottom: -18px;
		left: -28px;
		z-index: 3;
	}
	.image-game {
		display: block;
		position: absolute;
		width: 124px;
		height: 140px;
		bottom: 42px;
		left: calc(50% - 124px / 2);
		z-index: 2;
		background: var(--gradient-g-1);
		padding: 2px;
		border-radius: 12px;
		img {
			width: 100%;
			height: 100%;
			border-radius: inherit;
		}
	}
}
</style>
